<template>
  <div class="emailkits">
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-title>
              Emailkit:

              <span v-if="emailkit" class="pl-1"> {{ emailkit.name }} </span>
            </v-card-title>
            <v-card-text v-if="emailkit">
              <dl>
                <dt>Afzender</dt>
                <dd>
                  {{ emailkit.from_name }} &lt;{{ emailkit.from_address }}@{{
                    emailkit.domain
                  }}&gt;
                </dd>
                <dt>Reply to:</dt>
                <dd v-if="emailkit.replyto_address">
                  {{ emailkit.replyto_name }} &lt;{{
                    emailkit.replyto_address
                  }}&gt;
                </dd>
                <dd v-else>
                  {{ emailkit.from_name }} &lt;{{ emailkit.from_address }}@{{
                    emailkit.domain
                  }}&gt;
                </dd>
                <dt>Onderwerp</dt>
                <dd>{{ emailkit.subject }}</dd>
              </dl>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-title>
              Statistieken
            </v-card-title>
            <v-list-item three-line>
              <v-list-item-content>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value="filterDates"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Filter verstuurperiode"
                      prepend-icon="event"
                      readonly
                      range
                      clear-icon="close"
                      clearable
                      v-on="on"
                      @click:clear="filterDates = []"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterDates"
                    no-title
                    scrollable
                    range=""
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(filterDates)"
                      >OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>Algemeen</v-subheader>
            <v-row>
              <v-col md="5" lg="4" sm="6" xs="12">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th>Aantal keer verstuurd</th>
                        <td class="text-right">{{ numSents | formatCount }}</td>
                      </tr>
                      <tr>
                        <th>Verstuurd naar</th>
                        <td class="text-right">
                          {{ numLeads | formatCount }} leads
                        </td>
                      </tr>
                      <tr>
                        <th>Opens</th>
                        <td class="text-right">
                          {{ numOpens | formatCount }} ({{
                            numOpens | formatPercentageOf(numSents)
                          }})
                        </td>
                      </tr>
                      <tr>
                        <th>Kliks</th>
                        <td class="text-right">
                          {{ total_clicks | formatCount }} ({{
                            total_clicks | formatPercentageOf(numOpens)
                          }})
                        </td>
                      </tr>
                      <tr>
                        <th>Unieke kliks</th>
                        <td class="text-right">
                          {{ total_global_unique_clicks | formatCount }} ({{
                            total_global_unique_clicks
                              | formatPercentageOf(numOpens)
                          }})
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col></v-row
            >
            <v-subheader>Klik details</v-subheader>
            <v-data-table
              ref="dataTableOverview"
              :loading="emailkit === null || stats_loading"
              no-data-text="Geen links in e-mail"
              loading-text="Bezig met laden.."
              :headers="linkTableHeaders"
              :items="links"
              class="elevation-1"
              hide-default-footer
              :disable-pagination="true"
            >
              <template slot="body.append">
                <tr>
                  <td colspan="2"><strong>Totaal</strong></td>
                  <td class="text-right">
                    {{ total_clicks | formatCount }} ({{
                      total_clicks | formatPercentageOf(numOpens)
                    }})
                  </td>
                  <td class="text-right">
                    {{ total_unique_clicks | formatCount }} ({{
                      total_unique_clicks | formatPercentageOf(numOpens)
                    }})
                  </td>
                </tr>
              </template>
              <template v-slot:item.link_count="{ item }">
                <a
                  href="#preview"
                  style="text-decoration: none;"
                  @click="highlightLinkInPreview(item.link_count)"
                  >{{ item.link_count }} <v-icon small="">visibility</v-icon></a
                >
              </template>
              <template v-slot:item.url="{ item }">
                <a :href="item.url">{{ item.url }}</a>
              </template>
              <template v-slot:item.clicks="{ item }"
                >{{ item.clicks | formatCount }} ({{
                  item.clicks | formatPercentageOf(numOpens)
                }})
              </template>
              <template v-slot:item.clicks_unique="{ item }">
                {{ item.clicks_unique | formatCount }} ({{
                  item.clicks_unique | formatPercentageOf(numOpens)
                }})
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-title>
              Voorbeeld
            </v-card-title>
            <v-card-text>
              <iframe
                id="preview"
                :style="{
                  height: emailkit_preview_height + 10 + 'px',
                }"
                :src="previewURL"
              ></iframe>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomerPage from "@/mixins/customerpage";

export default {
  name: "EmailkitStats",
  mixins: [CustomerPage],
  props: {
    emailkitId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      emailkit: null,
      filterDates: [],
      menu: null,
      emailkit_preview_height: 100,
      stats: null,
      stats_loading: false,
      statsRequestCounter: 0,
    };
  },
  computed: {
    numOpens() {
      if (this.stats && this.stats.sent_stats) {
        return this.stats.sent_stats.num_opens;
      }
      return 0;
    },
    numSents() {
      if (this.stats && this.stats.sent_stats) {
        return this.stats.sent_stats.num_sents;
      }
      return 0;
    },
    numLeads() {
      if (this.stats && this.stats.sent_stats.num_unique_leads) {
        return this.stats.sent_stats.num_unique_leads;
      }
      return 0;
    },
    total_global_unique_clicks() {
      if (this.stats && this.stats.sent_stats.total_global_unique_clicks) {
        return this.stats.sent_stats.total_global_unique_clicks;
      }
      return 0;
    },

    previewURL() {
      return (
        this.axios.defaults.baseURL +
        "customer/" +
        this.customerId +
        "/emailkits/" +
        this.emailkitId +
        "/preview"
      );
    },
    linkTableHeaders() {
      return [
        {
          text: "Link",
          value: "link_count",
        },
        {
          text: "URL",
          value: "url",
        },
        {
          text: "# Kliks",
          value: "clicks",
          align: "end",
        },
        {
          text: "# Unieke kliks per link",
          value: "clicks_unique",
          align: "end",
        },
      ];
    },
    links() {
      if (this.emailkit) {
        return this.emailkit.links.map((e) => {
          return {
            link_count: e.reference + 1,
            url: e.url,
            clicks:
              this.stats &&
              this.stats.click_stats &&
              this.stats.click_stats[e.reference]
                ? this.stats.click_stats[e.reference].total_clicks || 0
                : this.stats
                ? 0
                : "",
            clicks_unique:
              this.stats &&
              this.stats.click_stats &&
              this.stats.click_stats[e.reference]
                ? this.stats.click_stats[e.reference].num_unique_clicks || 0
                : this.stats
                ? 0
                : "",
          };
        });
      }
      return [];
    },
    dateRangeText: {
      get() {
        if (this.filterDates.length == 0) {
          return "";
        }
        return this.filterDates.join(" tot ");
      },
      set(value) {
        this.filterDates = [];
      },
    },
    total_clicks() {
      if (this.stats && this.stats.click_stats) {
        let total = 0;

        for (let s in this.stats.click_stats) {
          total += this.stats.click_stats[s].total_clicks;
        }
        return total;
      }
      return 0;
    },
    total_unique_clicks() {
      if (this.stats && this.stats.click_stats) {
        let total = 0;

        for (let s in this.stats.click_stats) {
          total += this.stats.click_stats[s].num_unique_clicks;
        }
        return total;
      }
      return 0;
    },
    breadcrumbs() {
      let b = this.defaultBreadcrumbs.slice();

      b.push({
        text: "Emailkits",
        exact: true,
        to: {
          name: "emailkits",
          params: {
            customerId: this.customerId,
          },
        },
      });

      if (this.emailkit) {
        b.push({
          disabled: true,
          text: this.emailkit.name,
        });
      }

      return b;
    },
  },
  watch: {
    filterDates() {
      this.loadStats();
    },
  },
  mounted() {
    // listen to height postMessage to adjust iframe height
    let _this = this;
    window.addEventListener(
      "message",
      function (e) {
        if (e.data.event == "emailkit_height") {
          _this.emailkit_preview_height = e.data.data.height;
        }
      },
      false
    );
    // load exports
    this.axios
      .get("customer/" + this.customerId + "/emailkits/" + this.emailkitId)
      .then((res) => {
        this.emailkit = res.data.emailkit;
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          // sponsor not found
          this.$router.push({
            name: "customer",
            params: {
              customerId: this.customerId,
            },
          });
        }
      });

    this.loadStats();
  },
  methods: {
    highlightLinkInPreview(link_count) {
      // postMessage to iframe
      document.getElementById("preview").contentWindow.postMessage(
        {
          event: "highlight_link",
          data: {
            link_id: link_count,
          },
        },
        "*"
      );
    },
    loadStats() {
      this.stats_loading = true;
      this.statsRequestCounter += 1;

      this.axios
        .get(
          "customer/" +
            this.customerId +
            "/emailkits/" +
            this.emailkitId +
            "/stats",
          {
            params: {
              filterDates: this.filterDates,
              requestCounter: this.statsRequestCounter,
            },
          }
        )
        .then((res) => {
          if (this.statsRequestCounter == res.data.requestCounter) {
            // prevent updating old requests
            this.stats = res.data;
            this.stats_loading = false;
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
dt {
  font-weight: bold;
}
iframe {
  border: none;
  width: 100%;
}
</style>
